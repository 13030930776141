import React, { useEffect, useMemo } from 'react'
import type { AdvancedTableProps, ArrayResponse, Order } from './AdvancedTable.types'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box, IconButton } from '@mui/material'
import RequestStatus from 'components/RequestStatus'
import * as Styled from './AdvancedTable.styles'
import { useAppContext } from 'context/AppContext/AppContext'
// import TableNav from './TableNav'
import useQueryGet from 'hooks/useQueryGet'
// import { renderDate } from 'utils/renderDate'
import FiltersProvider, { useFilters } from 'context/FiltersContext/FiltersContext'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import { renderDate } from 'utils/renderDate'

const AdvancedTable: <T extends object>(props: AdvancedTableProps<T>) => JSX.Element = ({
  headings,
  name,
  endpoint,
  renderRow,
  defaultRowsPerPage = '100',
  formHandler,
  formId,
  orderField,
  orderDir,
  type
}) => {
  const { filters, updateFilter } = useFilters()
  const { isDarkMode } = useAppContext()

  useEffect(() => {
    updateFilter({
      take: defaultRowsPerPage,
      page: '1',
      order: orderDir ?? 'ASC',
      orderField: orderField ?? ((headings.find(e => e.field)?.field as string) || 'id')
    })
  }, [])

  const { data, isLoading, isRefetching } = useQueryGet<ArrayResponse<NonNullable<typeof type>>>({
    queryKey: [`${name}-query`, filters],
    endpoint,
    options: {
      params: {
        ...filters,
        createdAtStart: undefined,
        createdAtFinish: undefined,
        createdDateStart: filters.createdAtStart ? renderDate(filters.createdAtStart.toString(), false, '-', true) : undefined,
        createdDateEnd: filters.createdAtFinish ? renderDate(filters.createdAtFinish.toString(), false, '-', true) : undefined
      }
    },
    refetchDeps: [filters]
  })

  const content = useMemo(() => {
    if (data === undefined || 'error' in data || ('data' in data && data.data instanceof Array && data.data.length === 0)) {
      return (
        <TableRow>
          <TableCell colSpan={headings.length} sx={{ backgroundColor: 'transparent' }}>
            <RequestStatus data={data} isLoading={isLoading} />
          </TableCell>
        </TableRow >
      )
    }

    return data.data.map(renderRow)
  }, [data, isLoading])

  const handleChangeOrder = (orderField: string): void => {
    const getDirection = (name: string, dir: Order['dir']): Order['dir'] => {
      if (name === orderField) {
        return dir === 'ASC' ? 'DESC' : 'ASC'
      }
      return 'ASC'
    }
    updateFilter({ order: getDirection(filters.orderField?.toString(), filters.order as Order['dir']), orderField })
  }

  if (data === undefined || 'error' in data || isRefetching) {
    return <RequestStatus data={data} isLoading={isLoading || isRefetching} />
  }

  return (
    <Box>
      <form id={formId} onSubmit={formHandler}>
        <TableContainer component={Paper} sx={{ overflowX: 'auto', background: 'transparent', boxShadow: 'none' }}>
          <Table sx={{ borderSpacing: '0px 10px', borderCollapse: 'separate' }} aria-label='custom pagination table'>
            <Styled.TableHead>
              <TableRow>
                {headings.map(({ name, sort, field, width, hide }) =>
                  // eslint-disable-next-line
                  hide === undefined || (hide !== undefined && !hide)
                    ? <TableCell key={name} width={width}>
                      <Box display='flex' alignItems='center' sx={{ whiteSpace: 'nowrap' }}>
                        {name}
                        {sort !== undefined && sort
                          ? (
                            <IconButton color={filters.orderField === field ? 'success' : 'default'} onClick={() => { handleChangeOrder(field) }}>
                              {filters.order === 'ASC' || filters.orderField !== field ? <ExpandMoreOutlinedIcon /> : <ExpandLessOutlinedIcon />}
                            </IconButton>)
                          : null}
                      </Box>
                    </TableCell>
                    : null
                )}
              </TableRow>
            </Styled.TableHead>
            <Styled.TableBody isdarkmode={isDarkMode.toString()}>
              {content}
            </Styled.TableBody>
          </Table>
        </TableContainer>
      </form>
      {data.meta.itemCount > 5
        ? <Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                colSpan={headings.length}
                count={data.meta.itemCount}
                rowsPerPage={+filters.take}
                page={+filters.page - 1}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                onPageChange={(_, num) => { updateFilter({ page: (num + 1).toString() }) }}
                onRowsPerPageChange={e => { updateFilter({ take: e.target.value, page: '1' }) }}
              />
            </TableRow>
          </TableFooter>
        </Table>
        : null}
    </Box>
  )
}

const AdvancedTableWrapper: <T extends object>(props: AdvancedTableProps<T>) => JSX.Element = (props) => {
  return (
    <FiltersProvider
      key={props.name}
      options={props.options}
      headings={props.headings}
      customFilters={props.customFilters ?? []}
      isExportMode={props.isExportMode ?? false}
      exportText={props.exportText}
      disableFilters={props.disableFilters}
      onUsedFilters={props.onUsedFilters}
      orderField={props.orderField}
      changeExportMode={props.changeExportMode}
      orderDir={props.orderDir}>
      <AdvancedTable {...props} />
    </FiltersProvider>
  )
}

export default AdvancedTableWrapper
