import React, { useState } from 'react'
import type { StatusButtonsProps } from './StatusButtons.types'
import { StatusTypes } from 'pages/Applications/applicationStatuses'
import { type UpdateStatusData } from 'pages/Applications/Applications.types'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined'
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

const StatusButtons = ({ status, id, updateHandler, flow, prize, receiptValidation, size, direction }: StatusButtonsProps): JSX.Element => {
  const [newData, setNewData] = useState<null | UpdateStatusData>(null)
  const { t } = useTranslation()

  const clearConfirmationData = (): void => {
    setNewData(null)
  }

  const renderButton = (
    title: string,
    color: React.ComponentProps<typeof Button>['color'],
    data: UpdateStatusData,
    startIcon: JSX.Element,
    withConfirmation?: boolean
  ): JSX.Element => (
    <Button
      size={size}
      variant='contained'
      color={color}
      startIcon={startIcon}
      onClick={() => {
        if (withConfirmation !== undefined && withConfirmation) {
          setNewData(data)
        } else {
          updateHandler(data)
        }
      }}
    >
      {title}
    </Button>
  )

  const isAccepted = status === StatusTypes.Accepted
  const isFraud = status === StatusTypes.Fraud
  const isRejected = status === StatusTypes.Rejected
  const isReciptValidation = status === StatusTypes.ReciptValidation
  const isGwp = flow === 'gwp'
  const isDigital = prize === 'digital'
  const isAuto = receiptValidation === 'auto'
  const isChangeDisabled = (isGwp && isDigital) && (isAuto || (!isAuto && isAccepted))

  if (isChangeDisabled) {
    return <>{null}</>
  }

  const isManualReciptValidation = !isAuto && isReciptValidation
  const isManualRejected = !isAuto && isRejected
  const isRejectBtnEnabled = (!isAuto && isReciptValidation) || isManualReciptValidation
  const isFraudBtnEnabled = isAccepted || isManualReciptValidation || isManualRejected
  const isAcceptBtnEnabled = isFraud || isManualReciptValidation || isManualRejected

  return (
    <>
      {isAcceptBtnEnabled
        ? renderButton(t('application.accept'), 'success', { id, newStatusId: 2 }, <CheckOutlinedIcon />)
        : null}
      {isRejectBtnEnabled
        ? renderButton(t('application.reject'), 'error', { id, newStatusId: 3 }, <PriorityHighOutlinedIcon />)
        : null}
      {isFraudBtnEnabled
        ? renderButton(t('application.fraud'), 'warning', { id, newStatusId: 4 }, <ReportGmailerrorredOutlinedIcon />, true)
        : null}
      {renderButton(t('common.delete'), 'error', { id, newStatusId: 7 }, <DeleteOutlinedIcon />, true)}
      {newData !== null
        ? <ConfirmDialog
          open={newData !== null}
          onAgree={() => { updateHandler(newData); clearConfirmationData() }}
          onDisagree={clearConfirmationData}
          question={t('dialogs.areYouSure')}
        />
        : null}
    </>
  )
}

export default StatusButtons
