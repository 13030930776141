import { formatInTimeZone } from 'date-fns-tz'

const renderNumber = (num: number): string => num < 10 ? `0${num}` : `${num}`

export const renderDate = (dateStr?: string, renderHour: boolean = false, separator: string = ' / ', reverse: boolean = false): string => {
  if (dateStr === undefined) {
    return ''
  }

  const serverTZ = process.env.REACT_APP_API_TIMEZONE
  const date = serverTZ !== undefined ? new Date(formatInTimeZone(new Date(dateStr), serverTZ, 'yyyy-MM-dd HH:mm')) : new Date(dateStr)

  if (date.toString() === 'Invalid Date') {
    return 'Bad Date Format'
  }

  const day = renderNumber(date.getDate())
  const month = renderNumber(date.getMonth() + 1)
  const year = renderNumber(date.getFullYear())
  const hour = renderNumber(date.getHours())
  const minutes = renderNumber(date.getMinutes())

  if (reverse) {
    return `${year}${separator}${month}${separator}${day}`
  }

  if (renderHour) {
    return `${day}${separator}${month}${separator}${year}, ${hour}:${minutes}`
  }

  return `${day}${separator}${month}${separator}${year}`
}
