import React, { type ComponentProps } from 'react'
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined'
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined'
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined'
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined'
import VideogameAssetOutlinedIcon from '@mui/icons-material/VideogameAssetOutlined'
import type Avatar from '@mui/material/Avatar'

export const applicationStatuses: Array<{
  value: string
  color: ComponentProps<typeof Avatar>['color']
  icon: JSX.Element
}> = [
  { value: 'email verification', color: 'primary.main', icon: <MarkEmailUnreadOutlinedIcon /> },
  { value: 'recipt validation', color: 'info.main', icon: <ReceiptLongOutlinedIcon /> },
  { value: 'accepted', color: 'success.main', icon: <CheckOutlinedIcon /> },
  { value: 'rejected', color: 'error.main', icon: <PriorityHighOutlinedIcon /> },
  { value: 'fraud', color: 'warning.main', icon: <ReportGmailerrorredOutlinedIcon /> },
  { value: 'prize draw winner', color: 'success.main', icon: <EmojiEventsOutlinedIcon /> },
  { value: 'prize draw looser', color: 'warning.main', icon: <SentimentDissatisfiedOutlinedIcon /> },
  { value: 'deleted', color: 'error.main', icon: <DeleteForeverOutlinedIcon /> },
  { value: 'game', color: 'info.main', icon: <VideogameAssetOutlinedIcon /> }
]

export enum StatusTypes {
  EmailVerification,
  ReciptValidation,
  Accepted,
  Rejected,
  Fraud,
  PrizeDrawWinner,
  PrizeDrawLoser,
  Deleted,
  Game,
}
