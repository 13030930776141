import { Button, Typography, Card, CardHeader, CardContent, CardActions } from '@mui/material'
import Box from '@mui/material/Box'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import RequestStatus from 'components/RequestStatus'
import { type ApiLanguage } from 'pages/Markets/Form/MarketForm.types'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import type { ApiApplicationDetails, APIApplication } from 'types/Application'
import type { ApplicationFormProps, HandleSubmitProps } from './ApplicationForm.types'
import ApplicationFormFields from './ApplicationFormFields'
import { useAppContext } from 'context/AppContext/AppContext'
import { axiosPatch } from 'connectors/axiosPatch'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { useAlert } from 'context/AlertContext'
import useQueryGet from 'hooks/useQueryGet'
import { useAuthContext } from 'context/AuthContext/AuthContext'

const ApplicationEditForm = ({ type, market, selectedMarketId }: ApplicationFormProps): JSX.Element => {
  const { user } = useAppContext()
  const { token, refreshToken } = useAuthContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const params = useParams()
  const queryClient = useQueryClient()
  const { changeMessage } = useAlert()

  const { data: applicationData, isLoading: applicationLoading } = useQueryGet<ApiApplicationDetails>({
    queryKey: ['application-details', params.id],
    endpoint: `applications/${params.id ?? 1}`,
    queryOptions: { enabled: params.id !== undefined }
  })

  const { data: marketDetailsData, isLoading: marketDetailsLoading } = useQueryGet<{ formFields: Record<keyof HandleSubmitProps, boolean> }>({
    queryKey: ['market-details-details', params.id],
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing, @typescript-eslint/restrict-template-expressions, @typescript-eslint/strict-boolean-expressions
    endpoint: `markets/data/${market?.code || (applicationData !== undefined && !('error' in applicationData) && applicationData?.market_code) || ''}`,
    queryOptions: { enabled: market?.code !== undefined || (applicationData !== undefined && !('error' in applicationData) && applicationData?.market_code !== undefined) }
  })

  const { mutate: editApplication, data: editData, reset: resetEdit } = useMutation(
    async (marketData: FormData) => await axiosPatch<APIApplication>(token, refreshToken, `applications/${params.id ?? ''}`, marketData),
    {
      onSuccess: (_, variables) => {
        if (applicationData !== undefined && !('error' in applicationData)) {
          const newValues = Object.fromEntries(Object.entries(applicationData).map(([key, value]) => [key, variables.get(key) ?? value]))

          changeMessage(t('common.success'), 'success', () => {
            queryClient.setQueryData(
              ['application-details', params.id],
              { ...newValues }
            )
            navigate('/applications')
          })
        }
      },
      onError: (err) => {
        console.error(err)
      }
    }
  )

  useEffect(() => {
    if (editData !== undefined && 'error' in editData) {
      changeMessage(editData?.error?.data?.message, 'error', resetEdit)
    }
  }, [editData])

  const marketId = (applicationData !== undefined && !('error' in applicationData)) ? applicationData.application_marketLanguageId : ''

  const { data: marketLanguages, isLoading: loadingLanguages } = useQueryGet<ApiLanguage[]>({
    queryKey: ['application-languages', market, marketId, applicationData],
    endpoint: `market-languages/${marketId ?? ''}`,
    queryOptions: { enabled: marketId !== undefined }
  })

  const dynamicFields = useMemo(() => {
    if (marketDetailsData !== undefined && !('error' in marketDetailsData) && applicationData !== undefined && !('error' in applicationData)) {
      const result = Object.fromEntries(Object.keys(marketDetailsData.formFields).map(field => {
        const fieldName: keyof ApiApplicationDetails = `application_${field}` as keyof ApiApplicationDetails

        // @ts-expect-next-line
        return ([field, fieldName in applicationData ? applicationData?.[fieldName] : ''])
      }))

      return result as HandleSubmitProps
    }

    return {}
  }, [marketDetailsData])

  const onHandleSubmit = async (data: HandleSubmitProps): Promise<any> => {
    if (market === undefined && (applicationData === undefined || (applicationData !== undefined && 'error' in applicationData))) {
      return
    }

    const { marketLanguageId, ...restData } = data
    const fd = new FormData()

    const body = {
      ...restData,
      marketLanguageId: marketLanguageId.toString(),
      updatedById: user?.id.toString() ?? ''
    }
    Object.entries(body).forEach(([key, value]) => {
      if (value instanceof Array) {
        value.forEach(file => {
          if (file instanceof File) {
            fd.append('files', file)
          }
        })
      } else if (typeof value === 'boolean') {
        fd.append(key, value.toString())
      } else {
        fd.append(key, value)
      }
    })
    const country = market?.name ?? (applicationData !== undefined && !('error' in applicationData) ? applicationData?.market_name : '')
    fd.set('country', country)
    editApplication(fd)
  }

  if (applicationData === undefined || 'error' in applicationData) {
    return <RequestStatus data={applicationData} isLoading={applicationLoading} />
  }

  if (marketLanguages === undefined || 'error' in marketLanguages) {
    return <RequestStatus data={marketLanguages} isLoading={loadingLanguages} />
  }

  if (marketDetailsData === undefined || 'error' in marketDetailsData) {
    return <RequestStatus data={marketDetailsData} isLoading={marketDetailsLoading} />
  }

  return (
    <>
      <Typography variant='h4' component='h1' mb={2}>
        {t('common.editField', { field: t('navigation.applications') })}
      </Typography>
      <Card>
        <CardHeader title={<Button
          type='button'
          variant='outlined'
          onClick={() => { navigate('/applications') }}
          startIcon={<ChevronLeft />}
        >
          {t('common.backListText')}
        </Button>} />
        <CardContent>
          <Box sx={{ maxWidth: 640, width: '100%' }}>
            <ApplicationFormFields
              isAddForm={false}
              state={state}
              onHandleSubmit={onHandleSubmit}
              languages={marketLanguages}
              marketFields={marketDetailsData.formFields}
              loadedFiles={applicationData !== undefined && !('error' in applicationData) ? applicationData.files : []}
              defaultValue={{
                marketLanguageId: applicationData.application_marketLanguageId ?? marketLanguages[0].id,
                email: applicationData.application_email !== 'undefined' ? applicationData.application_email : '',
                fullName: applicationData.application_fullName !== 'undefined' ? applicationData.application_fullName : '',
                ipAddress: applicationData.application_ipAddress !== 'undefined' ? applicationData.application_ipAddress : '',
                files: [],
                consent1: ((applicationData?.application_consent1).length > 0) ? applicationData.application_consent1 === 'true' : false,
                consent2: ((applicationData?.application_consent2).length > 0) ? applicationData.application_consent2 === 'true' : false,
                consent3: ((applicationData?.application_consent3).length > 0) ? applicationData.application_consent3 === 'true' : false,
                apartmentNumber: applicationData.application_apartmentNumber ?? '',
                barcode: applicationData.application_barcode ?? '',
                city: applicationData.application_city ?? '',
                comments: applicationData.application_comments ?? '',
                country: applicationData.application_country ?? '',
                buildingNumber: applicationData.application_apartmentNumber ?? '',
                buildingName: applicationData.application_buildingName ?? '',
                phoneNumber: applicationData.application_phoneNumber ?? '',
                postalCode: applicationData.application_postalCode ?? '',
                province: applicationData.application_province ?? '',
                streetName: applicationData.application_streetName ?? '',
                closedQuestion: applicationData.application_closedQuestion ?? '',
                openQuestion: applicationData.application_openQuestion ?? '',
                ...dynamicFields
              }}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button
            type='button'
            variant='outlined'
            onClick={() => { navigate('/applications') }}
            startIcon={<ChevronLeft />}
          >
            {t('common.backListText')}
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default ApplicationEditForm
