import React, { useMemo } from 'react'
import Button from '@mui/material/Button'
import Input from 'components/Input'
import { useTranslation } from 'react-i18next'
import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { type HandleSubmitProps, type ApplicationFormFieldsProps } from './ApplicationForm.types'
import Box from '@mui/material/Box'
import Select from 'components/Select'
import type { ApiLanguage } from 'pages/Markets/Form/MarketForm.types'
import { MenuItem, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import FileUpload from 'components/FileUpload'
import { imageExt } from 'config/extensions'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

const ApplicationFormFields = ({ isAddForm, onHandleSubmit, languages, defaultValue, loadedFiles, marketFields }: ApplicationFormFieldsProps): JSX.Element => {
  const { t } = useTranslation()

  const defaultValues: HandleSubmitProps = {
    ...defaultValue,
    ipAddress: defaultValue?.ipAddress ?? '',
    email: defaultValue?.email ?? '',
    fullName: defaultValue?.fullName ?? '',
    barcode: defaultValue?.barcode ?? '',
    phoneNumber: defaultValue?.phoneNumber ?? '',
    streetName: defaultValue?.streetName ?? '',
    buildingNumber: defaultValue?.buildingNumber ?? '',
    buildingName: defaultValue?.buildingName ?? '',
    apartmentNumber: defaultValue?.apartmentNumber ?? '',
    province: defaultValue?.province ?? '',
    postalCode: defaultValue?.postalCode ?? '',
    city: defaultValue?.city ?? '',
    country: defaultValue?.country ?? '',
    comments: defaultValue?.comments ?? '',
    marketLanguageId: defaultValue?.marketLanguageId ?? languages[0].id,
    files: defaultValue?.files ?? [],
    consent1: defaultValue?.consent1 ?? false,
    consent2: defaultValue?.consent2 ?? false,
    consent3: defaultValue?.consent3 ?? false,
    closedQuestion: defaultValue?.closedQuestion ?? '',
    openQuestion: defaultValue?.openQuestion ?? ''
  }

  const validationSchema = useMemo(() => {
    const defaultValidation: Record<keyof HandleSubmitProps, any> = {
      fullName: yup.string().required(t('common.required') ?? 'Required'),
      email: yup.string().email(),
      streetName: yup.string(),
      buildingName: yup.string(),
      buildingNumber: yup.string(),
      apartmentNumber: yup.string(),
      city: yup.string(),
      province: yup.string(),
      country: yup.string(),
      postalCode: yup.string(),
      phoneNumber: yup.string(),
      comments: yup.string().max(1000),
      barcode: yup.string(),
      consent1: yup.boolean().required(),
      consent2: yup.boolean().required(),
      consent3: yup.boolean().required(),
      files: yup.array().of(yup.mixed()),
      ipAddress: yup.string(),
      marketLanguageId: yup.string(),
      closedQuestion: yup.string(),
      openQuestion: yup.string()
    }

    Object.entries(marketFields).forEach(([key, value]) => {
      if (key === 'email' && value) {
        defaultValidation.email = yup.string().email(t('forms.invalid', { field: t('application.email') }) ?? 'Bad Format').required(t('common.required') ?? 'Required')
        return
      }
      if (value && key !== 'files') {
        defaultValidation[key as keyof HandleSubmitProps] = yup.string().required(t('common.required') ?? 'Required')
      }
      if (key === 'comments') {
        defaultValidation.comments = defaultValidation.comments.max(1000)
      }
    })
    if (marketFields.files !== undefined && marketFields.files && isAddForm) {
      defaultValidation.files = defaultValidation.files.min(1, t('common.required') ?? 'Required').required(t('common.required') ?? 'Required')
    }

    return yup.object().shape(defaultValidation)
  }, [marketFields])

  const { handleSubmit, control, formState: { errors } } = useForm<HandleSubmitProps>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  const { fields, append } = useFieldArray({
    control,
    // @ts-expect-error-next-line
    name: 'files'
  })

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type='submit'
          color='success'
          variant='contained'
          sx={{ mb: 2 }}
          startIcon={<DoneOutlinedIcon />}
        >
          {t(isAddForm ? 'common.add' : 'common.save')}
        </Button>
      </Box>
      <Select<ApiLanguage, HandleSubmitProps>
        control={control}
        name='marketLanguageId'
        elements={languages}
        label={t('application.language')}
        renderFunction={(app) => <MenuItem key={app.id} value={app.id}>{app.name}</MenuItem>}
      />
      <Divider sx={{ mt: 2 }} />
      <Input<HandleSubmitProps> name='fullName' label={`${t('application.fullName')} *`} control={control} />
      <Input<HandleSubmitProps> name='email' label={`${t('application.email')} ${marketFields.email ? ' *' : ''}`} control={control} />
      <Input<HandleSubmitProps> name='ipAddress' label={t('application.ip')} control={control} />
      <Divider />
      {Object.entries(marketFields).filter(([key]) => key !== undefined && key !== null && key !== 'files' && key !== 'email').map(([field, required]) => (
        <Input<HandleSubmitProps> key={field} name={field as keyof HandleSubmitProps} label={`${t(`application.${field}`)}${required ? ' *' : ''}`} control={control} />
      ))}
      <Divider />
      <Input<HandleSubmitProps> type='switch' name='consent1' label={`${t('application.consent')} 1 *`} control={control} />
      <Input<HandleSubmitProps> type='switch' name='consent2' label={`${t('application.consent')} 2 *`} control={control} />
      <Input<HandleSubmitProps> type='switch' name='consent3' label={`${t('application.consent')} 3 *`} control={control} />
      <Divider />
      <Box>
        {loadedFiles !== undefined
          ? loadedFiles.map(({ file }) => (
            <a
              key={file.id}
              href={`${process.env.REACT_APP_API_URL}/${file.path.replace('files\\', '') ?? ''}${file.name}.${file.extension}`}
              rel="noreferrer"
              target='_blank'
            >
              <img
                src={`${process.env.REACT_APP_API_URL}/${file.path.replace('files\\', '') ?? ''}${file.name}.${file.extension}`}
                alt=''
                style={{ objectFit: 'contain', objectPosition: 'center' }}
                height={100}
                width={100}
              />
            </a>
          ))
          : null}
      </Box>
      <Typography fontSize={20}>{t('application.attachments')} {marketFields.files ? ' *' : ''}</Typography>
      {fields.map((file, idx) => (
        <Box key={file.id} display='flex'>
          <FileUpload<HandleSubmitProps>
            control={control}
            extensions={imageExt}
            name={`files.${idx}`}
            label={`${t('application.attachment')} ${idx + 1}`}
          />
        </Box>
      ))}
      <Button onClick={() => {
        append(null)
      }}>
        {t('common.add')}
      </Button>
      {errors?.files !== undefined
        ? <Typography whiteSpace='break-spaces' fontSize={12} color='error'><>{errors.files.message}</></Typography>
        : null}
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type='submit'
          color='success'
          variant='contained'
          sx={{ mt: 2 }}
          startIcon={isAddForm ? <AddOutlinedIcon /> : <DoneOutlinedIcon />}
        >
          {t(isAddForm ? 'common.add' : 'common.save')}
        </Button>
      </Box>
    </form>
  )
}

export default ApplicationFormFields
