import { type Filters } from 'context/FiltersContext/FiltersContext.types'

export const generateQueryParams = (obj: Filters): URLSearchParams => {
  const params = new URLSearchParams()

  Object.entries(obj).forEach(([key, value]) => {
    if (value instanceof Array) {
      value.forEach(el => {
        params.append(`${key}[]`, el)
      })
    } else {
      params.append(key, value.toString())
    }
  })

  return params
}

export const readQueryParams = (params: URLSearchParams): Filters => {
  let values = {}
  // @ts-expect-error-next-line
  for (const key of params.keys()) {
    const value = (key as string).includes('[]') ? params.getAll(key) : params.get(key)
    values = {
      ...values,
      [key.replace('[]', '')]: value
    }
  }

  return values
}
