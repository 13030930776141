import { type Control } from 'react-hook-form'
import React from 'react'
import Input from 'components/Input'
import type { Continent } from 'types/Continent'
import type { HandleSubmitProps, Language } from './MarketForm.types'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select from 'components/Select'
import { type CountryType, countries } from 'config/countries'
import { timezones } from 'config/timezones'

const Config = ({ control, continents, languagesArray, isAddForm }: { isAddForm: boolean, control: Control<HandleSubmitProps, any>, languagesArray: Language[], continents: Continent[] }): JSX.Element => {
  const { t } = useTranslation()

  const emptyElement: { id: '', name: string } = { id: '', name: t('common.select') }

  return (
    <Box>
      <Select<CountryType, HandleSubmitProps>
        control={control}
        name='name'
        withText
        label={t('market.country')}
        elements={[...countries, { code: '', label: '' }]}
        withTextProps={{
          isOptionEqualToValue: (option, value) => {
            if (typeof value === 'string') {
              return option.label === value
            }
            return option.label === value.label
          },
          disabled: !isAddForm,
          renderOption: (props, option) => (
            <MenuItem component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} value={option.label} {...props}>
              <img loading='lazy' width='20' src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`} alt='' />
              {option.label} ({option.code})
            </MenuItem>
          )
        }}

      />
      <Input<HandleSubmitProps>
        name='description'
        label={t('market.description')}
        control={control}
        textInputProps={{ multiline: true }}
      />
      <Select<Continent, HandleSubmitProps>
        control={control}
        name='groupId'
        label={t('market.group')}
        elements={continents}
        renderFunction={(continent) => (
          <MenuItem key={continent.id} value={continent.id}>
            {continent.name}
          </MenuItem>
        )}
      />
      <Select<Language, HandleSubmitProps>
        control={control}
        name='languages'
        label={t('market.language')}
        multiple
        withText
        withTextProps={{
          isOptionEqualToValue: (option, value) => {
            if (typeof value === 'string') {
              return option.name === value
            }
            return option.name === value.name
          },
          disabled: !isAddForm
        }}
        getOptionLabel={(language) => language.name}
        elements={languagesArray}
      />
      <Select<string, HandleSubmitProps>
        control={control}
        withText
        getOptionLabel={(value) => value}
        name='timeZone'
        withTextProps={{ disabled: !isAddForm }}
        label={t('market.timezone')}
        elements={[...timezones, '']}
      />
      <Select<{ id: HandleSubmitProps['flow'] | '', name: string }, HandleSubmitProps>
        control={control}
        name='flow'
        label={t('market.flow')}
        disabled={!isAddForm}
        elements={[{ id: 'pd', name: 'Prize Draw' }, { id: 'gwp', name: 'Gift with Purchase' }, emptyElement]}
        renderFunction={(flow) => (
          <MenuItem key={flow.id} value={flow.id}>
            {flow.name}
          </MenuItem>
        )}
      />
      <Select<HandleSubmitProps['entryValidation'], HandleSubmitProps>
        control={control}
        name='entryValidation'
        label={t('market.entryLimitation')}
        elements={[0, 1, 3]}
        renderFunction={(value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        )}
      />
      <Select<{ id: HandleSubmitProps['entryValidationType'], name: string }, HandleSubmitProps>
        control={control}
        name='entryValidationType'
        label={t('market.entryLimitationPeriod')}
        elements={[
          { id: 'day', name: t('prize.day') },
          { id: 'week', name: t('prize.week') },
          { id: 'month', name: t('prize.month') },
          { id: 'NA', name: t('prize.wholePromo') }
        ]}
        renderFunction={(flow) => (
          <MenuItem key={flow.id} value={flow.id}>
            {flow.name}
          </MenuItem>
        )}
      />
      <Select<{ id: HandleSubmitProps['entryValidationField'], name: string }, HandleSubmitProps>
        control={control}
        name='entryValidationField'
        label={t('market.entryValidationField')}
        elements={[
          { id: 'email', name: t('market.email') },
          { id: 'household', name: t('market.household') },
          { id: 'email and household', name: t('market.emailAndHousehold') },
          { id: 'phone', name: t('market.phone') }
        ]}
        renderFunction={(flow) => (
          <MenuItem key={flow.id} value={flow.id}>
            {flow.name}
          </MenuItem>
        )}
      />
      <Select<{ id: HandleSubmitProps['prize'] | '', name: string }, HandleSubmitProps>
        control={control}
        name='prize'
        label={t('market.prize')}
        elements={[{ id: 'physical', name: 'Physical' }, { id: 'digital', name: 'Digital' }, { id: 'both', name: 'Both' }, emptyElement]}
        renderFunction={(prize) => (
          <MenuItem key={prize.id} value={prize.id}>
            {prize.name}
          </MenuItem>
        )}
      />
      <Select<{ id: HandleSubmitProps['verification'] | '', name: string }, HandleSubmitProps>
        control={control}
        name='verification'
        label={t('market.verification')}
        elements={[{ id: 'email', name: 'E-mail' }, { id: 'sms', name: 'SMS' }, emptyElement]}
        renderFunction={(verification) => (
          <MenuItem key={verification.id} value={verification.id}>
            {verification.name}
          </MenuItem>
        )}
      />
      <Select<{ id: HandleSubmitProps['receiptValidation'] | '', name: string }, HandleSubmitProps>
        control={control}
        name='receiptValidation'
        label={t('market.receiptValidation')}
        elements={[{ id: 'auto', name: 'Auto' }, { id: 'manual', name: 'Manual' }, emptyElement]}
        renderFunction={(receiptValidation) => (
          <MenuItem key={receiptValidation.id} value={receiptValidation.id}>
            {receiptValidation.name}
          </MenuItem>
        )}
      />
      <Input
        type='switch'
        control={control}
        name='emailService'
        label={t('market.emailService')}
      />
      <Select<{ id: HandleSubmitProps['emailRecipient'], name: string }, HandleSubmitProps>
        control={control}
        name='emailRecipient'
        label={t('market.emailRecipient')}
        elements={[{ id: 'NA', name: 'N/A' }, { id: 'winner', name: 'Winner' }, { id: 'all', name: 'All' }]}
        renderFunction={(recipient) => (
          <MenuItem key={recipient.id} value={recipient.id}>
            {recipient.name}
          </MenuItem>
        )}
      />
      <Input<HandleSubmitProps> control={control} type='date' name='holdingPageDate' label={t('market.holdingPageDate')} />
      <Input<HandleSubmitProps> control={control} type='date' name='promoLiveDate' label={t('market.promoLiveDate')} />
      <Input<HandleSubmitProps> control={control} type='date' name='promoEndDate' label={t('market.promoEndDate')} />
      <Input<HandleSubmitProps> control={control} type='date' name='closedPageEndDate' label={t('market.closedPageEndDate')} />
      <Input<HandleSubmitProps> control={control} type='date' name='personalDataDeletedBy' label={t('market.personalDataDeletedBy')} />
    </Box>
  )
}

export default Config
